<template>
  <section>
    <b-form>
      <!-- estado -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-3">
              <Dropdown
                :Items="dropdownItemsEstados"
                v-model="viewModel.Estados[0].ufOrigemId"
                :valueDefault="$t('sectionNcm.Create.Uf')"
                :label="$t('sectionNcm.Create.Uf')"
                disabled="true"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 sm:col-span-9">
              <input-default
                v-model="viewModel.Estados[0].ufOrigem"
                :label="$t('sectionNcm.Create.StateOrigin')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 sm:col-span-3">
              <Dropdown
                :Items="dropdownItemsEstados"
                v-model="viewModel.Estados[0].ufDestinoId"
                :valueDefault="$t('sectionNcm.Create.Uf')"
                :label="$t('sectionNcm.Create.Uf')"
                disabled="true"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 sm:col-span-9">
              <input-default
                v-model="viewModel.Estados[0].ufDestino"
                :label="$t('sectionNcm.Create.StateDestination')"
                disabled="true"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- outras informações -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 xl:col-span-6">
              <Dropdown
                :Items="dropdownItemsCst"
                v-model="viewModel.Estados[0].cstId"
                :valueDefault="$t('sectionNcm.Create.Cst')"
                :label="$t('sectionNcm.Create.Cst')"
                disabled="true"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 xl:col-span-6">
              <Dropdown
                :Items="dropdownItemsCsosn"
                v-model="viewModel.Estados[0].csosnId"
                :valueDefault="$t('sectionNcm.Create.Csosn')"
                :label="$t('sectionNcm.Create.Csosn')"
                disabled="true"
              >
              </Dropdown>
            </div>
          </div>
        </div>
        <div class="col-span-8"></div>
      </div>
      <!-- obrigatorios -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 lg:col-span-3">
              <input-default
                v-model="viewModel.Estados[0].aliquotaICMS"
                :label="$t('sectionNcm.Create.IcmsPercentage')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-3">
              <input-default
                v-model="viewModel.Estados[0].aliquotaICMSInterna"
                :label="$t('sectionNcm.Create.InternationalIcms')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-3">
              <input-default
                v-model="viewModel.Estados[0].aliquotaMVA"
                :label="$t('sectionNcm.Create.MvaPer')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-3">
              <input-default
                v-model="viewModel.Estados[0].aliquotaFCP"
                :label="$t('sectionNcm.Create.FcpPercent')"
                disabled="true"
              />
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </section>
</template>

<script type="module">
import InputDefault from "@core/components/commons/inputs/InputDefault";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

import { BForm } from "bootstrap-vue";

export default {
  name: "SupplementaryInformationNcm",
  components: {
    BForm,
    InputDefault,
    Dropdown,
  },
  props: {
    viewModel: {
      type: Object,
    },
  },
  data() {
    return {
      dropdownItemsCst: [],
      dropdownItemsCsosn: [],
      dropdownItemsEstados: [],
    };
  },
  created() {
    this.ObterSeletorEstados();
    this.ObterSeletorTributosCst();
    this.ObterSeletorTributosCsosn();
  },
  methods: {
    ObterSeletorTributosCst() {
      this.$http({
        url: `/tributo/obter-seletor-tributos-tipo/${0}`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsCst = res.data;
      });
    },
    ObterSeletorTributosCsosn() {
      this.$http({
        url: `/tributo/obter-seletor-tributos-tipo/${1}`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsCsosn = res.data;
      });
    },
    ObterSeletorEstados() {
      this.$http({
        url: `/estado/obter-seletor-estados`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsEstados = res.data;
      });
    },
  },
};
</script>
