<template>
  <section>
    <b-form>
      <!-- NCM -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">
              <input-required
                v-model="viewModel.codigoNcm"
                :label="$t('sectionNcm.Create.Ncm')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <CheckboxDefault
                label="NCM de serviço"
                v-model="viewModel.ncmServico"
                disabled="true"
              />
            </div>
          </div>
        </div>

        <!-- DESCRIÇÃO -->
        <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-12">
              <input-required
                v-model="viewModel.descricao"
                :label="$t('sectionNcm.Create.Description')"
                disabled="true"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Resto -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="viewModel.percentualMva"
                :label="$t('sectionNcm.Create.MvaPercent')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="viewModel.codigoNcmEx"
                :label="$t('sectionNcm.Create.Excode')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="viewModel.aliquotaNacional"
                :label="$t('sectionNcm.Create.NationalAliq')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="viewModel.aliquotaImportacao"
                :label="$t('sectionNcm.Create.ImportAliq')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.cstPisEntradaCodTributo"
                :label="$t('sectionNcm.Create.PisEntrance')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.cstPisEntradaDescricao"
                :label="$t('sectionNcm.Create.Description')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.cstPisSaidaCodTributo"
                :label="$t('sectionNcm.Create.PisExit')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.cstPisSaidaDescricao"
                :label="$t('sectionNcm.Create.Description')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.cstCofinsEntradaCodTributo"
                :label="$t('sectionNcm.Create.CofinsEntrance')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.cstCofinsEntradaDescricao"
                :label="$t('sectionNcm.Create.Description')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.cstCofinsSaidaCodTributo"
                :label="$t('sectionNcm.Create.CofinsExit')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.cstCofinsSaidaDescricao"
                :label="$t('sectionNcm.Create.Description')"
                disabled="true"
              />
            </div>
          </div>
        </div>
        <div class="col-span-8"></div>
      </div>
      <!-- aliquota -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="viewModel.aliquotaPis"
                :label="$t('sectionNcm.Create.AliqPis')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 lg:col-span-4 gap-4">
              <input-default
                v-model="viewModel.aliquotaCofins"
                :label="$t('sectionNcm.Create.AliqCofins')"
                disabled="true"
              />
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </section>
</template>

<script>
import InputRequired from "@core/components/commons/inputs/InputRequired";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import CheckboxDefault from "@core/components/commons/checkbox/CheckboxDefault";
import { BForm } from "bootstrap-vue";

export default {
  components: {
    BForm,
    InputRequired,
    InputDefault,
    CheckboxDefault,
  },
  props: {
    viewModel: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
