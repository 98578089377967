<template>
  <section class="w-full">
    <header-register
      :showButtonView="(previewButtonIsVisible = false)"
      @register="handleActivePageOperation"
      >{{ $t("sectionNcm.titleNcm") }}
    </header-register>
    <main class="w-full">
      <ManageNcmView />
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManageNcmView from "@/components/ncm/components/view/ManageNcmView.vue";

export default {
  name: "NcmEdit",
  components: {
    HeaderRegister,
    ManageNcmView,
  },
};
</script>
