<template>
  <div>
    <section class="-mt-4">
      <b-tabs class="w-full">
        <b-tab title="Geral">
          <GeneralNcmInformation class="mt-8" :viewModel="viewModel" />
        </b-tab>
        <b-tab title="Complemento">
          <SupplementaryInformationNcm class="mt-8" :viewModel="viewModel" />
        </b-tab>
      </b-tabs>
    </section>
    <footer class="w-full flex items-center justify-start gap-5 my-10">
      <Button
        class="bg-gray-900 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-gray-700 transition-all duration-300"
        buttonText="Voltar"
        :onClick="redirectUrl"
      />
    </footer>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralNcmInformation from "./GeneralNcmInformation";
import SupplementaryInformationNcm from "./SupplementaryInformationNcm";
import Button from "@core/components/commons/button/Button";

export default {
  name: "ManageNcmView",
  components: {
    BTabs,
    BTab,
    Button,
    GeneralNcmInformation,
    SupplementaryInformationNcm,
  },
  data() {
    return {
      viewModel: {
        codigoNcm: null,
        descricao: null,
        ncmServico: null,
        percentualMva: null,
        codigoNcmEx: null,
        aliquotaNacional: null,
        aliquotaImportacao: null,

        cstPisEntradaId: null,
        cstPisEntradaCodTributo: null,
        cstPisEntradaDescricao: null,

        cstPisSaidaId: null,
        cstPisSaidaCodTributo: null,
        cstPisSaidaDescricao: null,

        cstCofinsEntradaId: null,
        cstCofinsEntradaCodTributo: null,
        cstCofinsEntradaDescricao: null,

        cstCofinsSaidaId: null,
        cstCofinsSaidaCodTributo: null,
        cstCofinsSaidaDescricao: null,

        aliquotaPis: null,
        aliquotaCofins: null,
        Estados: [],
      },
    };
  },
  created() {
    this.RecuperarUser(this.$router.currentRoute.params.id);
  },
  methods: {
    RecuperarUser(id) {
      this.$http({
        url: `/ncm/obter/${id}`,
        method: "Get",
      }).then((response) => {
        this.viewModel.codigoNcm = response.data.codigoNcm;
        this.viewModel.descricao = response.data.descricao;
        this.viewModel.ncmServico = response.data.ncmServico;
        this.viewModel.percentualMva = response.data.percentualMva;
        this.viewModel.codigoNcmEx = response.data.codigoNcmEx;
        this.viewModel.aliquotaNacional = response.data.aliquotaNacional;
        this.viewModel.aliquotaImportacao = response.data.aliquotaImportacao;
        this.viewModel.cstPisEntradaId = response.data.cstPisEntradaId;
        this.viewModel.cstPisSaidaId = response.data.cstPisSaidaId;
        this.viewModel.cstCofinsEntradaId = response.data.cstCofinsEntradaId;
        this.viewModel.cstCofinsSaidaId = response.data.cstCofinsSaidaId;
        this.viewModel.aliquotaPis = response.data.aliquotaPis;
        this.viewModel.aliquotaCofins = response.data.aliquotaCofins;

        this.viewModel.Estados = [];

        var estado = response.data.estados;

        this.viewModel.Estados[0] = {
          ufOrigemId: estado[0]?.ufOrigemId || null,
          ufOrigem: estado[0]?.ufOrigem || null,
          ufDestinoId: estado[0]?.ufDestinoId || null,
          ufDestino: estado[0]?.ufDestino || null,
          cst: estado[0]?.cst || null,
          cstId: estado[0]?.cstId || null,
          csosn: estado[0]?.csosn || null,
          csosnId: estado[0]?.csosnId || null,
          aliquotaICMS: estado[0]?.aliquotaICMS || null,
          aliquotaICMSInterna: estado[0]?.aliquotaICMSInterna || null,
          aliquotaMVA: estado[0]?.aliquotaMVA || null,
          aliquotaFCP: estado[0]?.aliquotaFCP || null,
        };
        this.RecuperarPisCofins(this.viewModel.cstPisEntradaId, "PisEntrada");
        this.RecuperarPisCofins(this.viewModel.cstPisSaidaId, "PisSaida");
        this.RecuperarPisCofins(
          this.viewModel.cstCofinsEntradaId,
          "CofinsEntrada"
        );
        this.RecuperarPisCofins(this.viewModel.cstCofinsSaidaId, "CofinsSaida");
      });
    },
    RecuperarPisCofins(id, campo) {
      this.$http({
        url: `/tributo/obter/${id}`,
        method: "GET",
      }).then((res) => {
        if (campo == "PisEntrada") {
          this.viewModel.cstPisEntradaCodTributo = res.data.codigoTributo;
          this.viewModel.cstPisEntradaDescricao = res.data.descricao;
        }
        if (campo == "PisSaida") {
          this.viewModel.cstPisSaidaCodTributo = res.data.codigoTributo;
          this.viewModel.cstPisSaidaDescricao = res.data.descricao;
        }
        if (campo == "CofinsEntrada") {
          this.viewModel.cstCofinsEntradaCodTributo = res.data.codigoTributo;
          this.viewModel.cstCofinsEntradaDescricao = res.data.descricao;
        }
        if (campo == "CofinsSaida") {
          this.viewModel.cstCofinsSaidaCodTributo = res.data.codigoTributo;
          this.viewModel.cstCofinsSaidaDescricao = res.data.descricao;
        }
      });
    },
    redirectUrl() {
      return this.$router.push("/ncm");
    },
  },
};
</script>

<style></style>
